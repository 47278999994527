import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    partner: "",
    whichToShow: "keno",
    day_block: "",

    // partnerURL: "https://keno.jodanagenta.com/graphql",
    // spinPartnerURL: "https://spin.jodanagenta.com/graphql",

    // partnerURL: "https://keno.bet.apicevirtual.com/graphql",
    // spinPartnerURL: "https://spin.bet.apicevirtual.com/graphql",

    // partnerURL: "https://keno.bet.apiceplaybet.com/graphql",
    // spinPartnerURL: "https://spin.bet.apiceplaybet.com/graphql",

    partnerURL: "https://keno.play.apicevirtualbet.com/graphql",
    spinPartnerURL: "https://spin.play.apicevirtualbet.com/graphql",

    // partnerURL: "https://keno.agentone.apicevirtual.com/graphql",
    // spinPartnerURL: "https://spin.agentone.apicevirtual.com/graphql",

    // partnerURL: "https://keno.bet.apicebetvirtual.com/graphql",
    // spinPartnerURL: "https://spin.bet.apicebetvirtual.com/graphql",
  },
  getters: {},
  mutations: {
    loginPartner(state, partner) {
      state.partner = partner;
    },
    day_block(state, day_block) {
      state.day_block = day_block;
    },
    wichToSwitch(state, whichToShow) {
      state.whichToShow = whichToShow;
    },
  },
  actions: {
    loginPartner(context, partner) {
      context.commit("loginPartner", partner);
    },
    day_block(context, day_block) {
      context.commit("day_block", day_block);
    },

    wichToSwitch(context, whichToShow) {
      context.commit("wichToSwitch", whichToShow);
    },
  },

  plugins: [createPersistedState()],
  modules: {},
});
